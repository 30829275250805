import React from 'react';
import Icon from './Icon';

const Footer = () => {
  return(
    <footer>
      <Icon image="canada" />
    </footer>
  )
}

export default Footer;